import React from "react"
import GeneralPest from "../generalPest"
import ratings from "../../../assets/home/rating.png"
import BannerCarousel from "./BannerCarousel"
import "./style.scss"

export default function Hero({
  data,
  bannerVideo,
  bannerImage1,
  bannerImage2,
  offerIncludes,
  offerFeatures,
}) {
  return (
    <div
      className="home-hero"
      style={
        bannerImage1 === null && bannerImage2 === null
          ? {
              backgroundImage: `url(${data.src ? data.src.url : null})`,
            }
          : bannerImage1 !== null && bannerImage2 === null
          ? {
              backgroundImage: `url(${bannerImage1 ? bannerImage1.url : null})`,
            }
          : {}
      }
    >
      <div className="home-hero-container">
        {bannerImage1 !== null && bannerImage2 !== null ? (
          <BannerCarousel
            bannerVideo={bannerVideo}
            bannerImage1={bannerImage1}
            bannerImage2={bannerImage2}
          />
        ) : null}
        <div className="banner-absolute">
          <div className="general">
            <GeneralPest
              offerIncludes={offerIncludes}
              offerFeatures={offerFeatures}
            />
          </div>
          <div className="google-ratings">
            <img src={ratings} alt="google-ratings" />
          </div>
        </div>
      </div>
      <div className="google-ratings-2">
        <img src={ratings} alt="google-ratings-2" />
      </div>
    </div>
  )
}
