import React from "react"
import { Button } from "@react-md/button"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby"
import "./style.scss"

export default function Blog2({ data }) {
  const blogLink = data.dataRaw.blog_link.uid
    ? `/blog/${data.dataRaw.blog_link.uid}`
    : "#"
  return (
    <div className="blog-blog2">
      <div className="container">
        <div className="blog-blog2-content">
          <div className="blog-blog2-content-image">
            {data.data.image ? (
              <img
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.15) 0px 10px 10px -10px, rgba(0, 0, 0, 0.25) 0px 8px 6px -3px",
                }}
                src={data.data.image.url}
                alt=""
              />
            ) : null}
          </div>
          <Link href={blogLink}>
            <h3>{RichText.asText(data.data.title.raw)}</h3>
          </Link>
          <p className="center-p">Posted {data.data.date}</p>
          <p>{RichText.asText(data.data.content.raw)}</p>
          <div className="bottom-text">
            <div className="button-container">
              <Link to="/termite-inspection-sydney">
                <Button themeType="outline" className="blog2-button">
                  Termite
                </Button>
              </Link>
              <Link to="/commercial-pest-control">
                <Button themeType="outline" className="blog2-button">
                  Pest Control
                </Button>
              </Link>
              <Link to="/commercial-pest-control/pest-inspection">
                <Button themeType="outline" className="blog2-button">
                  Inspection
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
