import React from "react"
import { Button } from "@react-md/button"
import { RichText } from "prismic-reactjs"
import curve1 from "../../../assets/curves/curve-top-light-01.png"
import curve2 from "../../../assets/curves/curve-bottom-light-02.png"
import { Link } from "gatsby"
import "./style.scss"

export default function Blog1({ data, bg }) {
  const blogLink = data.dataRaw.blog_link.uid
    ? `/blog/${data.dataRaw.blog_link.uid}`
    : "#"
  return (
    <div
      className="blog-blog1"
      style={{
        backgroundImage: `linear-gradient(#333333, #1E1D18), url(${
          bg.bg_image ? bg.bg_image.url : null
        })`,
        backgroundBlendMode: "multiply",
      }}
    >
      <img
        src={curve1}
        style={{
          width: "100%",
          marginTop: "-6px",
        }}
        alt=""
      />
      <div className="container">
        <div className="blog-blog1-content">
          <div className="blog-blog1-content-image">
            {data.data.image ? (
              <img
                src={data.data.image.url}
                alt=""
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.15) 0px 10px 10px -10px, rgba(0, 0, 0, 0.25) 0px 8px 6px -3px",
                }}
              />
            ) : null}
          </div>
          <Link href={blogLink}>
            <h3>{RichText.asText(data.data.title.raw)}</h3>
          </Link>
          <p className="center-p">Posted {data.data.date}</p>
          <p>{RichText.asText(data.data.content.raw)}</p>
          <div className="bottom-text">
            <div className="button-container">
              <Link to="/termite-inspection-sydney">
                <Button themeType="outline" className="blog1-button">
                  Termite
                </Button>
              </Link>
              <Link to="/commercial-pest-control">
                <Button themeType="outline" className="blog1-button">
                  Pest Control
                </Button>
              </Link>
              <Link to="/commercial-pest-control/pest-inspection">
                <Button themeType="outline" className="blog1-button">
                  Inspection
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <img
        src={curve2}
        style={{
          width: "100%",
          marginBottom: "-6px",
        }}
        alt=""
      />
    </div>
  )
}
