import React from "react"
import { Button } from "@react-md/button"
import { Link } from "gatsby"
import GeneralPest from "../../home/generalPest"
import { RichText } from "prismic-reactjs"
import stone from "../../../assets/layout/stone.svg"
import "./style.scss"

export default function latest({ data, type }) {
  return (
    <div className="blog-latest">
      <div className="container">
        {type === "blog" ? (
          <div className="blog-blog1-content">
            <div className="blog-blog1-content-image">
              {data.body[0].primary.blog_image ? (
                <img src={data.body[0].primary.blog_image.url} alt="" />
              ) : null}
            </div>
            <h1>{data.body[0].primary.blog_title[0].text}</h1>
            <p className="center-p">
              Posted {data.body[0].primary.blog_post_date}
            </p>
            {data.body[0].primary.blog_description.map(description => (
              <p>{description.text}</p>
            ))}
            <Link to="/blog">
              <Button themeType="outline" className="blog1-button">
                Back to Blogs
              </Button>
            </Link>
          </div>
        ) : (
          <>
            <div className="blog-general-pest">
              <GeneralPest />
            </div>
            <h4> {RichText.asText(data.subtitle.raw)}</h4>
            <h2>
              <img src={stone} alt="" />
              {RichText.asText(data.title.raw)}
            </h2>
            <p className="center-description">
              {RichText.asText(data.description.raw)}
            </p>
          </>
        )}
      </div>
    </div>
  )
}
